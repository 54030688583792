import MarketplacePage from 'src/components/marketplace/landingPage/MarketplacePage'
import './styles.scss'
import Seo from 'src/components/seo/Seo'

const desc =
  'Quer se tornar um parceiro Decathlon? Cadastre-se no marketplace da maior loja de artigos esportivos e aumente suas vendas!" Visibilidade, Credibilidade, Audiência, Segurança e Atendimento você encontra na Decathlon!'

function Marketplace() {
  return (
    <>
      <Seo title="Marketplace | Decathlon" description={desc} />
      <MarketplacePage />
    </>
  )
}

export default Marketplace
